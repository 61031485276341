@if (isRecoveringSession()) {
  <div class="absolute z-50 inset-0 bg-light-tertiary dark:bg-dark">
    <div class="flex h-full items-center justify-center">
      <sc-spinner [classes]="'h-20 w-20'"></sc-spinner>
    </div>
  </div>
}
<div [hidden]="isRecoveringSession()" class="flex bg-light-tertiary dark:bg-dark h-screen w-screen">
  @if (!isLoaderVisible()) {
    @if (isUserLogged() && !isPrivacyPolicyPage()) {
      <sc-sidenav></sc-sidenav>
    }
    <div [ngClass]="isPrivacyPolicyPage()? 'p-0' : 'py-4 pr-4'" class="w-full flex flex-col overflow-hidden">
      @if (!isPrivacyPolicyPage()) {
        <sc-header (logout)="logoutUser()" [isUserLogged]="isUserLogged()"></sc-header>
      }
      <div class="w-full h-full overflow-hidden">
        <router-outlet></router-outlet>
      </div>
    </div>
  } @else {
    <sc-loading-view></sc-loading-view>
  }
</div>


<sc-modal id="upload-app">
  <div class="sc-text-default flex flex-col gap-4">
    <div class="text-lg pb-4">{{ 'update.title' | transloco }}</div>
    <div class="flex items-center gap-4">
      <i class="bi bi-info-circle text-primary text-4xl"></i>
      <div>
        <div>{{ 'update.subtitle' | transloco }}</div>
        <div>{{ 'update.description' | transloco }}</div>
      </div>
    </div>
    <div class="w-full flex justify-end gap-4 mt-6">
      <button (click)="updatePwa()" class="sc-btn-primary-static">{{ 'update.button' | transloco }}</button>
    </div>
  </div>
</sc-modal>
