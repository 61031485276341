{
  "name": "sectra24",
  "version": "2.1.7",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --ssl --host=dev-sectra24.ey.com",
    "build": "ng build",
    "build:production": "ng build --configuration=production",
    "build:development": "ng build --configuration=development",
    "clean:import": "ng generate @angular/core:cleanup-unused-imports",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.0",
    "@angular/common": "^19.2.0",
    "@angular/compiler": "^19.2.0",
    "@angular/core": "^19.2.0",
    "@angular/forms": "^19.2.0",
    "@angular/platform-browser": "^19.2.0",
    "@angular/platform-browser-dynamic": "^19.2.0",
    "@angular/router": "^19.2.0",
    "@angular/service-worker": "^19.2.0",
    "@mapbox/mapbox-gl-draw": "^1.4.1",
    "@mapbox/mapbox-gl-geocoder": "^5.0.1",
    "@ngneat/transloco": "^4.2.2",
    "@ngrx/effects": "^19.0.0",
    "@ngrx/operators": "^19.0.0",
    "@ngrx/router-store": "^19.0.0",
    "@ngrx/signals": "^19.0.0",
    "@ngrx/store": "^19.0.0",
    "@ngrx/store-devtools": "^19.0.0",
    "@tailwindcss/line-clamp": "^0.4.4",
    "@turf/turf": "^6.5.0",
    "bootstrap-icons": "^1.11.2",
    "chart.js": "^4.2.1",
    "chartjs-adapter-dayjs-3": "^1.2.3",
    "chartjs-plugin-datalabels": "^2.2.0",
    "chartjs-plugin-zoom": "^2.0.0",
    "dayjs": "^1.11.7",
    "dotenv": "^16.0.3",
    "flatpickr": "^4.6.13",
    "mapbox-gl": "^2.13.0",
    "mapbox-gl-controls": "^2.3.5",
    "ng2-pdf-viewer": "^10.2.2",
    "ngx-toastr": "^19.0.0",
    "quill": "^2.0.2",
    "rxjs": "~7.8.0",
    "swiper": "^9.0.5",
    "tslib": "^2.3.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.0",
    "@angular/cli": "~19.2.0",
    "@angular/compiler-cli": "^19.2.0",
    "@types/jasmine": "~4.3.0",
    "@types/mapbox__mapbox-gl-draw": "^1.3.3",
    "@types/mapbox__mapbox-gl-geocoder": "^4.7.3",
    "@types/mapbox-gl": "^2.7.10",
    "@types/node": "^18.13.0",
    "@types/quill": "^2.0.10",
    "autoprefixer": "^10.4.14",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "postcss": "^8.4.23",
    "tailwindcss": "^3.3.2",
    "tailwindcss-motion": "^1.0.0",
    "typescript": "~5.5.4",
    "video.js": "^8.21.0"
  }
}
