import {isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from "./core/core.module";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {TranslocoRootModule} from './transloco-root.module';
import {PageNotFoundComponent} from "./pages/page-not-found/page-not-found.component";
import {
  ResetPasswordActivationAccountComponent
} from "./pages/reset-password-activation-account/reset-password-activation-account.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputComponent} from "./shared/components/input/input.component";
import {ResetPasswordEmailComponent} from "./pages/reset-password-email/reset-password-email.component";
import {SpinnerComponent} from "./shared/components/spinner/spinner.component";
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import {registerLocaleData} from "@angular/common";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AuthModule} from "./core/auth/auth.module";
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {PrivacyPolicyComponent} from "./pages/privacy-policy/privacy-policy.component";
import {ToggleComponent} from "./shared/components/toggle/toggle.component";
import {CheckBoxComponent} from "./shared/components/check-box/check-box.component";
import {InfiniteScrollDirective} from "./core/directives/infinite-scroll.directive";
import {EffectsModule} from "@ngrx/effects";
// import {EntityDataModule} from "@ngrx/data";

registerLocaleData(localeIt, localeEn);


@NgModule({
  declarations: [
    AppComponent, PageNotFoundComponent, ResetPasswordActivationAccountComponent, ResetPasswordEmailComponent
  ],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    AppRoutingModule,
    TranslocoRootModule,
    CoreModule,
    FormsModule,
    InputComponent,
    ReactiveFormsModule,
    SpinnerComponent,
    PdfViewerModule,
    AuthModule.forRoot(),
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode()}),
    // EntityDataModule.forRoot({}),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([]),
    PrivacyPolicyComponent,
    ToggleComponent,
    CheckBoxComponent,
    InfiniteScrollDirective],
  providers: [
    // {provide: FIREBASE_OPTIONS, useValue: environment.FIREBASE_CONF}
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
