<div
  class="main-container-height w-screen bg-light dark:bg-dark-secondary flex items-center justify-center dark:text-white absolute inset-0 overflow-y-auto p-1">
  <!--  <div class="container px-5 overflow-hidden">-->


  <div [ngClass]="{'top-20' : isActivationAccount()}"
       class="sc-card-reverse flex flex-col items-center justify-center relative ">
    @if (isActivationAccount()) {
      <div class="flex flex-col gap-6 h-[70vh] pb-10">
        <h3 class="text-lg text-center sm:text-left">{{ 'activationAccount.privacyPolicy'| transloco }}</h3>
        <div class="!bg-white max-w-[46rem] px-2 overflow-y-auto" scInfiniteScroll
             (infiniteScroll)="enableCheckBox()">
          <st-privacy-policy [isActivationAccount]="true" [isPage]="false"></st-privacy-policy>
        </div>
        <div class="flex gap-4 items-center pl-1">
          <st-check-box [disabled]="termAndConditionsDisabled()"
                        (onChange)="setTermAndConditionsAccepted($event)"></st-check-box>
          <p class="text-sm">{{ 'activationAccount.privacyPolicyAccepted'| transloco }}</p>
        </div>
      </div>
    }
    <div class="flex gap-6 w-full">
      <div class="flex flex-col gap-6 w-full items-center sm:items-start sm:w-[250px]">
        <h3
          class="text-lg text-center sm:text-left">{{ isActivationAccount() ? ('activationAccount.insertPassword' | transloco) : ('activationAccount.resetPassword' | transloco) }}</h3>
        <form [formGroup]="changePasswordForm" class="flex flex-col gap-6 w-[250px]">
          <div class="relative">
            <sc-input [formControl]="changePasswordForm.controls.newPassword"
                      [placeholder]="('activationAccount.newPassword' | transloco)"
                      class="w-full" classes="!pr-[70px]"
                      type="password"></sc-input>
            <p *ngIf="!!newPasswordStrength()"
               [ngClass]="newPasswordStrength()! < 3 ? 'text-orange-500' : newPasswordStrength()! < 5  ? 'text-warn' : 'text-success'"
               class="text-xs px-2 absolute right-1 top-2.5"
            >
              {{ getStrengthText() }}
            </p>
            <p *ngIf="changePasswordForm?.controls?.newPassword?.errors?.['invalidPassword']"
               class="text-xs text-danger px-2 pt-2 text-center">
              {{ 'activationAccount.passwordValidator' | transloco }}
            </p>
          </div>

          <div>
            <sc-input [formControl]="changePasswordForm.controls.confirmPassword"
                      [placeholder]="('activationAccount.confirmPassword' | transloco)"
                      class="w-full" type="password"></sc-input>
            <p *ngIf="changePasswordForm?.errors?.['passwordMismatch']"
               class="text-xs text-danger px-2 pt-2 text-center">
              {{ 'activationAccount.passwordsError' | transloco }}
            </p>
          </div>
        </form>
        <div class="w-full flex justify-end mt-4">
          <button (click)="resetPassword()"
                  [disabled]="changePasswordForm.invalid || (isActivationAccount() && !termAndConditionsAccepted)"
                  class="sc-btn-primary-static">
            {{ ('activationAccount.save' | transloco) }}
          </button>
        </div>
      </div>
      <div class="flex-1 sm:flex justify-end p-4 hidden">
        <img
          [src]="'assets/images/components/' + (isDarkMode() ? 'change-password-dark' : 'change-password-light') + '.png'"
          alt="change-password"
          class="h-[280px] object-contain"/>
      </div>
    </div>
  </div>

</div>
