import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {ThemeToggleComponent} from './components/sidenav/components/theme-toggle/theme-toggle.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth/interceptors/auth.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UserBadgeComponent} from './components/header/components/user-badge/user-badge.component';
import {AvatarComponent} from '../shared/components/avatar/avatar.component';
import {HeaderComponent} from './components/header/header.component';
import {TranslocoModule} from '@ngneat/transloco';
import {LanguageHandlerComponent} from './components/header/components/language-handler/language-handler.component';
import {IsAuthorizeDirective} from "./auth/directives/is-authorize.directive";
import {NotificationComponent} from './components/header/components/notification/notification.component';
import {AlertsSwiperComponent} from './components/header/components/alerts-swiper/alerts-swiper.component';
import {SpinnerComponent} from "../shared/components/spinner/spinner.component";
import {RouterLink} from "@angular/router";
import {ToggleComponent} from "../shared/components/toggle/toggle.component";
import {LoadingViewComponent} from './components/loading-view/loading-view.component';
import {InfiniteScrollDirective} from "./directives/infinite-scroll.directive";
import {ModalComponent} from "../shared/components/modal/modal.component";
import {SidePanelComponent} from "../shared/components/side-panel/side-panel.component";
import {SubmenuToggleDirective} from "../shared/directives/submenu-toggle.directive";

@NgModule({
  declarations: [
    SidenavComponent,
    ThemeToggleComponent,
    UserBadgeComponent,
    HeaderComponent,
    LanguageHandlerComponent,
    NotificationComponent,
    AlertsSwiperComponent,
    LoadingViewComponent,
  ],
  exports: [HeaderComponent, SidenavComponent, UserBadgeComponent, LoadingViewComponent],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AvatarComponent,
    TranslocoModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
      closeButton: true,
      easing: 'linear',
      easeTime: 200,
    }),
    IsAuthorizeDirective,
    SpinnerComponent,
    RouterLink,
    ToggleComponent,
    InfiniteScrollDirective,
    ModalComponent,
    SidePanelComponent,
    SubmenuToggleDirective,
  ],
})
export class CoreModule {
}
