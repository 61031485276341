import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal
} from '@angular/core';
import {fromEvent, Subject, takeUntil, timer} from "rxjs";
import {MetaData, Notification, NotificationType} from "../../../../../shared/models/utils";
import {Router} from "@angular/router";
import {SocketService} from "../../../../services/socket.service";
import {ModalControllerService} from "../../../../services/modal-controller.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'sc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnDestroy, OnInit {
  private unsubscribeAll$ = new Subject<void>();
  showSubmenu = signal<boolean>(false);
  selectedNotification: Notification | undefined = undefined;
  @Output() loadMoreNotificationChange = new EventEmitter<void>();
  @Output() onNotificationMenuClick = new EventEmitter<void>();
  @Input() isLoading: boolean = false;
  @Input() notifications: Notification[] = [];
  @Input() notificationPage: number = 1;

  metaRef: MetaData | undefined = undefined;

  notificationCount = signal<number>(0);

  getNotificationCount = computed(() => this.notificationCount() > 99 ? '99+' : this.notificationCount().toString());
  private sanitizer: DomSanitizer = inject(DomSanitizer);

  constructor(private readonly router: Router, private readonly socketService: SocketService, private modalController: ModalControllerService) {
    fromEvent(window, 'click')
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe((event: Event) => {
        const button = document.querySelector('#notification-submenu-button');
        if (button?.contains(event.target as Node)) {
          if (!this.showSubmenu() && this.notificationCount() > 0) {
            this.notificationCount.set(0);
            this.onNotificationMenuClick.emit();
          }
          this.showSubmenu.update((value) => !value);
          return;
        }
        timer(200).subscribe(() => {
          this.showSubmenu.set(false);
        });
      });
    this.socketService.notification$.pipe().subscribe((notification: Notification | undefined) => {
      if (notification) {
        new Audio('assets/audio/notification.mp3').play()
        this.notificationCount.set(this.notificationCount() + 1);
        this.notifications.unshift(notification);
        const bellIcon = document.querySelector('#bell-icon');
        if (bellIcon) {
          bellIcon.classList.add('bell-animation');
        }
      }
    });
  }

  @Input() set meta(meta: MetaData | undefined) {
    this.metaRef = meta;
    this.notificationCount.set(meta?.notificationNotRead ?? 0);
  }

  ngOnInit(): void {
    const bellIcon = document.querySelector('#bell-icon');
    if (bellIcon) {
      fromEvent(bellIcon, 'animationend')
        .pipe(takeUntil(this.unsubscribeAll$))
        .subscribe((event: Event) => {
          bellIcon.classList.remove('bell-animation');
        })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  loadMoreNotification() {
    if (this.metaRef?.last_page !== this.notificationPage) {
      this.loadMoreNotificationChange.emit();
    }
  }

  async handleNotification(notification: Notification, i: number): Promise<void> {
    if (!notification.payload.notExist) {
      await this.router.navigate([notification.payload.type + 's', ...notification.payload.referenceId.toString().split('/')]);
    }
    else {
      this.selectedNotification = notification;
      this.modalController.open('notification-modal')
    }

    if (!this.notifications[i].isRead) {
      this.notifications[i].isRead = true;
      this.onNotificationMenuClick.emit();
    }
  }

  getIcon(type: NotificationType, subType: string | undefined): string {
    if (type === NotificationType.checkIn) {
      if (subType === 'Check-in') {
        return 'bi-geo-alt'
      }
      if (subType === 'Warning') {
        return 'bi-exclamation-circle'
      }
      if (subType === 'Live-location') {
        return 'bi-cursor'
      }
    }
    if (type === NotificationType.travel) {
      return 'bi-airplane'
    }
    if (type === NotificationType.alert) {
      return 'bi-exclamation-triangle'
    }
    return 'bi-info-circle'
  }

  closeModal() {
    this.modalController.close('notification-modal');
    setTimeout(() => {
      this.selectedNotification = undefined;
    }, 200);
  }

  sanitizeMessage(message: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(message);
  }
}
