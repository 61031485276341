import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, Output, signal, WritableSignal,} from '@angular/core';
import {fromEvent, Subject, takeUntil} from 'rxjs';
import {User} from 'src/app/shared/models/user';
import {AuthService} from "../../../../auth/services/auth.service";
import {ThemeService} from "../../../../services/theme.service";

interface Option {
  label: string;
  description: string;
  icon: string;
  link: string;
  fragment?: string;
}

@Component({
  selector: 'sc-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserBadgeComponent implements AfterViewInit, OnDestroy {
  user: User | undefined;
  showSubmenu = signal<boolean>(false);
  isDark: WritableSignal<boolean> = signal<boolean>(false);
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleTravellerMode: EventEmitter<void> = new EventEmitter<void>();
  options: Option[] = [
    {
      label: 'Profile',
      description: 'View your profile',
      icon: 'bi bi-person',
      link: '/users/me',
    },
    // {
    //   label: 'Companies',
    //   description: 'All my companies',
    //   icon: 'bi bi-briefcase',
    //   link: '/profile',
    // },
    {
      label: 'Settings',
      description: 'Account settings',
      icon: 'bi bi-gear',
      link: '/users/me',
      fragment: 'settings',
    },
    // {
    //   label: 'TravellerMode',
    //   description: 'Enable/disable traveller mode',
    //   icon: 'bi bi-airplane',
    //   link: '/',
    // },
  ];
  private unsubscribeAll$ = new Subject<void>();

  constructor(private authService: AuthService, private themeService: ThemeService) {
    this.authService.getUser().pipe(takeUntil(this.unsubscribeAll$)).subscribe((user) => {
      this.user = user;
    });
    this.themeService.isDarkMode$.pipe(takeUntil(this.unsubscribeAll$)).subscribe((isDark: boolean) => {
      this.isDark.set(isDark);
    })
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  ngAfterViewInit(): void {
    fromEvent(window, 'click')
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe((event: Event) => {
        const button = document.querySelector('#user-profile-submenu-button');
        if (button?.contains(event.target as Node)) {
          this.showSubmenu.update((value) => !value);
          return;
        }
        this.showSubmenu.set(false);
      });
  }

  toggleTravellerModeClick(): void {
    this.toggleTravellerMode.emit();
  }

  logoutClick(): void {
    this.logout.emit();
  }

}
