<div class="flex items-center gap-3 w-full pb-4" id="header">
  <div class="flex-1">
    @if (isUserLoggedRef()) {
      <sc-alerts-swiper (onAlertClick)="navToAlertDetails($event)" [alerts]="alerts" [isLoading]="isLoadingAlert()"/>
    }
  </div>
  @if (isUserLoggedRef()) {
    <sc-notification
      (loadMoreNotificationChange)="loadNotification()"
      (onNotificationMenuClick)="updateNotificationRead()"
      [isLoading]="isLoadingNotification()"
      [meta]="notificationsMeta"
      [notificationPage]="notificationPage"
      [notifications]="notifications()"
    ></sc-notification>
    <sc-language-handler (onLanguageChange)="setUserLanguage($event)"></sc-language-handler>
    <sc-user-badge
      (logout)="logoutClick()"
      (toggleTravellerMode)="toggleTravellerMode()"
    ></sc-user-badge>
  }
</div>
