import {Component, OnDestroy, signal, WritableSignal} from '@angular/core';
import {LoaderService} from "../../services/loader.service";
import {Subject, takeUntil} from "rxjs";
import {AuthService} from "../../auth/services/auth.service";

@Component({
  selector: 'sc-loading-view',
  templateUrl: './loading-view.component.html',
  styleUrls: ['./loading-view.component.scss']
})
export class LoadingViewComponent implements OnDestroy {

  unsubscribe$: Subject<void> = new Subject<void>();

  isLoading: WritableSignal<boolean> = signal<boolean>(false);
  message: WritableSignal<string> = signal<string>('');

  isTravellerMode: WritableSignal<boolean> = signal<boolean>(false);

  constructor(public loaderService: LoaderService, private authService: AuthService) {
    this.loaderService.isLoading()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (isLoading) => {
          this.isLoading.set(isLoading);
        }
      });
    this.loaderService.getMessage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (message) => {
          this.message.set(message);
        }
      });
    this.authService.getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (user) => {
          this.isTravellerMode.set(user?.travellerMode ?? false);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
