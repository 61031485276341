<div
  class="h-screen w-screen bg-light dark:bg-dark-secondary flex items-center justify-center absolute inset-0 dark:text-white"
>
  <div class="container flex flex-col items-center justify-center px-5">
    <div class="sc-card-reverse flex gap-6 !p-6">
      <div class="flex flex-col justify-between gap-8">
        <h3 class="text-lg">{{ 'resetPassword.title' | transloco }}</h3>
        <div class="relative w-[300px]">
          <sc-input [formControl]="email" class="w-full" placeholder="Email" type="email"></sc-input>
          <ng-container *ngIf="showErrorMessageContainer()">
            <p [ngClass]="showErrorMessage() ? 'text-danger' : 'text-success'" class="text-sm m-2 pt-4">
              {{ showErrorMessage() ? ('resetPassword.error' | transloco) : ('resetPassword.emailSent' | transloco) }}
            </p>
          </ng-container>
        </div>

        <div class="w-full flex justify-end mt-4">
          <button (click)="resetPassword()" [disabled]="email.invalid || isLoading()" class="sc-btn-primary-static">
            <ng-container *ngIf="!isLoading(); else spinnerContainer">
              <span class="text-sm">{{ 'resetPassword.sendEmail' | transloco }}</span>
            </ng-container>
            <ng-template #spinnerContainer>
              <sc-spinner [classes]="'h-5 w-5'"></sc-spinner>
            </ng-template>
          </button>
        </div>
      </div>
      <div class="flex-1 sm:flex justify-end p-4 hidden">
        <img
          [src]="'assets/images/components/' + (isDarkMode() ? 'change-password-dark' : 'change-password-light') + '.png'"
          alt="change-password"
          class="h-[280px] object-contain"/>
      </div>
    </div>
  </div>
</div>
