import {Component, OnDestroy, signal, WritableSignal} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ResetPasswordForm} from "../../modules/users/users.models";
import {PasswordValidators} from "../../shared/validators";
import {Subject, takeUntil} from "rxjs";
import {ThemeService} from "../../core/services/theme.service";
import {ActivatedRoute, Router} from "@angular/router";
import {checkPasswordStrength} from "../../shared/utils/utils";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../core/auth/services/auth.service";

@Component({
  selector: 'app-reset-password-activation-account',
  templateUrl: './reset-password-activation-account.component.html',
  styleUrls: ['./reset-password-activation-account.component.scss']
})
export class ResetPasswordActivationAccountComponent implements OnDestroy {
  changePasswordForm: FormGroup<ResetPasswordForm> = new FormGroup<ResetPasswordForm>({
      newPassword: new FormControl<string | null>(null, [Validators.required, Validators.minLength(10), PasswordValidators.validatorCharacters]),
      confirmPassword: new FormControl<string | null>(null, [Validators.required, Validators.minLength(10)]),
    }, {validators: [PasswordValidators.match,]}
  );

  newPasswordStrength: WritableSignal<number | undefined> = signal<number | undefined>(undefined);
  isDarkMode: WritableSignal<boolean> = signal<boolean>(false);
  unsubscribe$: Subject<void> = new Subject<void>();
  private token: string = '';

  isActivationAccount: WritableSignal<boolean> = signal<boolean>(false);
  termAndConditionsDisabled = signal<boolean>(true);
  termAndConditionsAccepted = signal<boolean>(false);


  constructor(
    private themeService: ThemeService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private toastrService: ToastrService,
    private router: Router,
    private readonly toastService: ToastrService
  ) {
    this.themeService.isDarkMode$.pipe(takeUntil(this.unsubscribe$))
      .subscribe((isDarkMode: boolean) => {
        this.isDarkMode.set(isDarkMode);
      });
    this.changePasswordForm.controls.newPassword.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (value: string | null) => {
          if (!value) {
            this.newPasswordStrength.set(undefined);
            return;
          }
          this.newPasswordStrength.set(checkPasswordStrength(value));
        }
      });
    this.route.url.subscribe((url) => {
      if (!!url[0] && url[0].path === 'activation') {
        this.isActivationAccount.set(true)
      }
    });
    this.route.params.subscribe((params) => {
      if (params['token']) {
        this.token = params['token'];
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  getStrengthText() {
    if (!this.newPasswordStrength()) return '';
    const strength = this.newPasswordStrength() as number;
    if (strength < 3) {
      return 'Weak';
    }
    else if (strength < 5) {
      return 'Moderate';
    }
    else {
      return 'Strong';
    }
  }

  resetPassword() {
    if (this.changePasswordForm.invalid || !this.token) {
      console.error('Invalid form or user id');
      return;
    }
    if (!this.isActivationAccount()) {

      this.authService.resetPassword(this.token, this.changePasswordForm.get('newPassword')!.value as string).subscribe({
        next: async (response) => {
          this.toastrService.success('Password reset successfully');
          await this.router.navigate(['/auth/login']);
        },
        error: (err) => {
          const errors = err?.error?.errors;
          for (const key in errors) {
            this.toastService.error(errors[key]);
          }
          if (!errors) {
            this.toastService.error('Something went wrong');
          }
        }
      });
    }
    else {
      this.authService.activationAccount(this.token, this.changePasswordForm.get('newPassword')!.value as string).subscribe({
        next: async (response) => {
          this.toastrService.success('Account activated successfully');
          await this.router.navigate(['/auth/login']);
        },
        error: (err) => {
          const errors = err?.error?.errors;
          for (const key in errors) {
            this.toastService.error(errors[key]);
          }
          if (!errors) {
            this.toastService.error('Something went wrong');
          }
        }
      });
    }
  }

  enableCheckBox(): void {
    this.termAndConditionsDisabled.set(false);
  }

  setTermAndConditionsAccepted(value: boolean): void {
    this.termAndConditionsAccepted.set(value);
  }
}
