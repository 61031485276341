<div class="relative ">
  <div class="sc-card !p-1">
    <button class="h-8 w-8 flex justify-center items-center relative" id="notification-submenu-button" aria-label="notifications" submenuToggle (onClickOutside)="onClickOutside()"
      (click)="showSubmenu.set(true)">
      <i class="bi bi-bell sc-text-default bell-animation" id="bell-icon"></i>
      @if (getNotificationCount() !== '0') {
      <div class="rounded-full h-4 min-w-[1rem] bg-danger text-white text-xs flex items-center justify-center p-1 absolute -bottom-2 -right-2">
        {{ getNotificationCount() }}
      </div>
      }
    </button>
  </div>

  <sc-side-panel (infiniteScroll)="loadMoreNotification()" [classes]="'!backdrop-blur-none'" [direction]="'rightToLeft'" [show]="showSubmenu()" [title]="('common.notifications'| transloco)"
    [width]="'w-[350px]'" class="fixed right-4 top-[70px] bottom-4 z-[999] bg-light dark:bg-dark-secondary rounded-3xl" id="language-submenu">


    <!--      <div class="pt-2 sticky top-0 bg-light dark:bg-dark-secondary z-50">-->
    <!--        <p class="p-2 text-sm text-center">{{ 'common.notifications'| transloco }}</p>-->
    <!--      </div>-->
    @for (notification of notifications(); track notification.id; let i = $index) {
    <button (click)="handleNotification(notification, i)" class="flex items-center gap-4 w-full sc-hover sc-text-default">
      <div [ngClass]="!notification.isRead ? 'bg-primary bg-opacity-10' : ''" class="option-container sc-hover text-left">
        <i [ngClass]="getIcon(notification.payload.type, notification.payload.subType)" class="text-xl bi"></i>
        <div class="flex flex-col gap-1 max-w-full">
          <h4 class="text-sm">{{ notification.title }}</h4>
          <p [innerHTML]="sanitizeMessage(notification.message)" class="text-xs line-clamp-3"></p>
          <p class="text-xs font-thin ">{{ notification.createdAt | date : 'dd MMMM yyyy, HH:mm' }}</p>
        </div>
      </div>
    </button>
    } @empty {
    <div class="flex justify-center pb-4">
      <span class="sc-text-default"> {{ 'noData.notifications' | transloco }}</span>
    </div>
    }

    @if (isLoading) {
    @if (isLoading) {
    @for (i of [].constructor(4); track $index) {
    <div class="flex items-center gap-4 w-full">
      <div class="option-container !gap-1 relative">
        <div class="h-8 w-8 rounded-full sc-skeleton relative -left-1"></div>
        <div class="flex flex-col gap-1 max-w-full">
          <div class="h-6 w-24 rounded-md sc-skeleton"></div>
          <div class="h-4 w-32 rounded-md sc-skeleton"></div>
          <div class="h-4 w-36 rounded-md sc-skeleton"></div>
        </div>
      </div>
    </div>
    }
    }
    }

  </sc-side-panel>
</div>

<sc-modal class="sc-text-default" id="notification-modal">
  @if (selectedNotification) {
  <div>
    <h1 class="text-lg">{{ selectedNotification.title }}</h1>
    <div class="mt-4 mb-6">{{ selectedNotification.message }}</div>
  </div>
  }
  <div class="flex justify-end gap-4">
    <button (click)="closeModal()" class="sc-btn-primary">{{ 'common.cancel' | transloco }}</button>
    <button (click)="closeModal()" class="sc-btn-primary">Ok</button>
  </div>

</sc-modal>