@if (isRecoveringSession()) {
  <div class="absolute z-50 inset-0 bg-light-tertiary dark:bg-dark">
    <div class="flex h-full items-center justify-center">
      <sc-spinner [classes]="'h-20 w-20'"></sc-spinner>
    </div>
  </div>
}
<div [hidden]="isRecoveringSession()" class="flex bg-light-tertiary dark:bg-dark h-screen w-screen">
  <ng-container *ngIf="!isLoaderVisible() else loaderContainer">
    <sc-sidenav *ngIf="isUserLogged() && !isPrivacyPolicyPage()"></sc-sidenav>
    <div [ngClass]="isPrivacyPolicyPage()? 'p-0' : 'py-4 pr-4'" class="w-full flex flex-col overflow-hidden">
      <sc-header (logout)="logoutUser()" *ngIf="!isPrivacyPolicyPage()" [isUserLogged]="isUserLogged()"></sc-header>
      <div class="w-full h-full overflow-hidden">
        <router-outlet></router-outlet>
      </div>
    </div>
  </ng-container>
  <ng-template #loaderContainer>
    <sc-loading-view></sc-loading-view>
  </ng-template>
</div>

