<div class="sc-card w-full !p-0 h-10 grid grid-cols-10 min-w-[500px]">
  <div
    class="col-span-1 h-full rounded-l-full bg-red-700 flex items-center justify-center text-white uppercase font-bold p-1 text-sm">
    <span>{{ 'common.alerts' | transloco }}</span>
  </div>
  <section [ngClass]="{'enable-animation': !isLoading()}" class=" col-span-9 h-full sc-text-default px-1">
    <div class="marquee marquee--hover-pause">
      <ul [ngStyle]="{'animation-duration': slides().length * 7 +'s'}" class="marquee__content">
        <ng-container *ngIf="isLoading() else alertContainer">
          <li class="w-full h-10 flex gap-4 items-center pl-4">
            <span>{{ 'header.alerts.loading' | transloco }}</span>
            <sc-spinner [classes]="'h-5 w-5'"></sc-spinner>
          </li>
        </ng-container>
        <ng-template #alertContainer>
          <ng-container *ngIf="slides().length > 0 else noActiveAlertContainer">
            <li (click)="alertClicked(alert.id)"
                *ngFor="let alert of slides()"
                class="h-10 overflow-hidden flex items-center gap-2 cursor-pointer pr-16">
              <div [style.background-color]="getLevelBgColor(alert.level)" class="h-4 w-4 rounded-full"></div>
              <div class="uppercase">{{ alert.title }}</div>
              <!--              <div>-</div>-->
              <!--              <div class="truncate flex-1">{{htmlToString(alert.description)}}</div>-->
            </li>
          </ng-container>
          <ng-template #noActiveAlertContainer>
            <div class="w-full h-10 flex gap-4 items-center">
              {{ 'header.alerts.message' | transloco }}
            </div>
          </ng-template>
        </ng-template>
      </ul>
    </div>
  </section>
</div>
