import {Component, signal, WritableSignal} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {finalize, Subject, takeUntil} from "rxjs";
import {ThemeService} from "../../core/services/theme.service";
import {AuthService} from "../../core/auth/services/auth.service";

@Component({
  selector: 'app-reset-password-activation-account-email',
  templateUrl: './reset-password-email.component.html',
  styleUrls: ['./reset-password-email.component.scss']
})
export class ResetPasswordEmailComponent {
  email = new FormControl<string | null>(null, [Validators.required, Validators.email]);
  isDarkMode: WritableSignal<boolean> = signal<boolean>(false);
  unsubscribe$: Subject<void> = new Subject<void>();
  showErrorMessageContainer: WritableSignal<boolean> = signal<boolean>(false);
  showErrorMessage: WritableSignal<boolean> = signal<boolean>(false);
  isLoading: WritableSignal<boolean> = signal<boolean>(false);

  constructor(private themeService: ThemeService, private readonly authService: AuthService) {
    this.themeService.isDarkMode$.pipe(takeUntil(this.unsubscribe$))
      .subscribe((isDarkMode: boolean) => {
        this.isDarkMode.set(isDarkMode);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetPassword() {
    if (this.email.invalid) {
      console.error('Invalid email');
      return;
    }
    this.showErrorMessageContainer.set(false);
    this.isLoading.set(true);
    this.authService.sendEmail(this.email!.value as string)
      .pipe(finalize(() => {
        this.showErrorMessageContainer.set(true);
        this.isLoading.set(false);
      }))
      .subscribe({
        next: () => {
          this.showErrorMessage.set(false);
        },
        error: () => {
          this.showErrorMessage.set(true);
        }
      });
  }
}
