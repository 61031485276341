import {ChangeDetectionStrategy, Component, inject, signal} from '@angular/core';
import {NavigationEnd, Router, Scroll} from '@angular/router';
import {EnumRoles} from '../../auth/models/roles.model';
import {ThemeService} from "../../services/theme.service";
import {BreadCrumbsService} from "../../services/bread-crumbs.service";
import {AuthService} from "../../auth/services/auth.service";
import {environment} from "../../../../environments/environment";
import packageJson from '../../../../../package.json';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

interface SidenavItem {
  name: string;
  icon: string;
  route: string;
  clicked: boolean;
  subItems?: SidenavItem[];
  spacing?: boolean;
  roles?: string[];
}

@Component({
  selector: 'sc-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
  // services
  private readonly router = inject(Router);
  private readonly themeService = inject(ThemeService);
  private readonly breadcrumbService = inject(BreadCrumbsService);
  private readonly authService = inject(AuthService);

  sidenavItems = signal<SidenavItem[]>([
    {
      name: 'Dashboard',
      icon: 'bi bi-columns-gap',
      route: '/dashboard',
      clicked: false,
      roles: [
        EnumRoles.AdminEY,
        EnumRoles.Analyst,
        EnumRoles.Traveller,
        EnumRoles.CompanyAdmin,
      ],
    },
    {
      name: 'Companies',
      icon: 'bi bi-buildings',
      route: '/companies',
      clicked: false,
      spacing: true,
      roles: [EnumRoles.Analyst, EnumRoles.AdminEY, EnumRoles.CompanyAdmin, EnumRoles.Traveller],
    },
    {
      name: 'Users',
      icon: 'bi bi-people',
      route: '/users',
      clicked: false,
      spacing: false,
      roles: [
        EnumRoles.AdminEY,
        EnumRoles.Analyst,
        EnumRoles.CompanyAdmin,
      ],
    },
    {
      name: 'Travels',
      icon: 'bi bi-airplane',
      route: '/travels',
      clicked: false,
      roles: [
        EnumRoles.Analyst,
        EnumRoles.AdminEY,
        EnumRoles.CompanyAdmin,
        EnumRoles.Traveller,
      ],
    },
    {
      name: 'POI',
      icon: 'bi bi-pin-map',
      route: '/poi',
      clicked: false,
      spacing: true,
      roles: [EnumRoles.AdminEY, EnumRoles.Analyst, EnumRoles.CompanyAdmin],
    },
    {
      name: 'Checkins',
      icon: 'bi bi-geo-alt',
      route: '/checkins',
      clicked: false,
      roles: [
        EnumRoles.AdminEY,
        EnumRoles.Analyst,
        EnumRoles.CompanyAdmin,
        EnumRoles.Traveller,
      ],
    },
    {
      name: 'Alerts',
      icon: 'bi bi-exclamation-triangle',
      route: '/alerts',
      clicked: false,
      roles: [EnumRoles.AdminEY, EnumRoles.Analyst, EnumRoles.CompanyAdmin],
    },
    {
      name: 'World',
      icon: 'bi bi-globe-europe-africa',
      route: '',
      clicked: false,
      spacing: true,
      subItems: [
        {
          name: 'IATA codes',
          icon: 'bi bi-compass',
          route: '/iata',
          clicked: false,
        },
        {
          name: 'Countries',
          icon: 'bi bi-flag',
          route: '/countries',
          clicked: false,
        },
        {
          name: 'Cities',
          icon: 'bi bi-buildings',
          route: '/cities',
          clicked: false,
        },
        // {
        //   name: 'JSD',
        //   icon: 'bi bi-stoplights',
        //   route: '/jsd',
        //   clicked: false,
        // },
        {
          name: 'Hotels',
          icon: 'bi bi-house',
          route: '/hotels',
          clicked: false,
        },
      ],
      roles: [
        EnumRoles.AdminEY,
        EnumRoles.Analyst,
        EnumRoles.Traveller,
        EnumRoles.CompanyAdmin,
      ],
    },
    // {
    //   name: 'Analytics',
    //   icon: 'bi bi-file-bar-graph',
    //   route: '/analytics',
    //   clicked: false,
    //   spacing: false,
    //   roles: [
    //     EnumRoles.AdminEY,
    //     EnumRoles.Analyst,
    //     EnumRoles.Traveller,
    //     EnumRoles.CompanyAdmin,
    //   ],
    // },
    // {
    //   name: 'Chat',
    //   icon: 'bi bi-chat-dots',
    //   route: '/chat',
    //   clicked: false,
    //   spacing: false,
    //   roles: [
    //     EnumRoles.AdminEY,
    //     EnumRoles.Analyst,
    //     EnumRoles.Traveller,
    //     EnumRoles.CompanyAdmin,
    //   ],
    // },
    {
      name: 'Imports',
      icon: 'bi bi-gear',
      route: '/imports',
      clicked: false,
      spacing: true,
      roles: [
        EnumRoles.AdminEY,
      ],
    },
    {
      name: 'Imported-Data',
      icon: 'bi bi-folder',
      route: '/imported-data',
      clicked: false,
      roles: [
        EnumRoles.AdminEY,
        EnumRoles.Analyst
      ],
    },
  ]);

  currentRoute = '';
  isDarkMode = true;

  showMenuItems = signal<boolean>(false);
  isStaging = signal<boolean>(false);
  version = signal<string>(packageJson.version);

  constructor() {
    this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof Scroll) { // userd scroll with NavigationEnd beacuse on refresh page (after modified auth guard) NavigationEnd is not triggered
        if (event instanceof NavigationEnd)
          this.currentRoute = event.url;
        else
          this.currentRoute = event.routerEvent.url;

        this.sidenavItems.update((sidenavItems) => {
          return sidenavItems.map((sidenavItem: SidenavItem) => {
            return {
              ...sidenavItem,
              clicked: sidenavItem.route === this.currentRoute,
            };
          });
        });

        // this.sidenavItems.map((sidenavItem: SidenavItem) => {
        //   sidenavItem.subItems?.forEach((subItem: SidenavItem) => {
        //     if (subItem.route === this.currentRoute && !sidenavItem.clicked) {
        //       sidenavItem.clicked = true;
        //     }
        //   });
        // });
      }
      this.isStaging.set(environment.API_BASEURL.includes('staging') || environment.API_BASEURL.includes('be'));
    });
    this.themeService.isDarkMode$.pipe(takeUntilDestroyed()).subscribe((isDarkMode) => {
      this.isDarkMode = isDarkMode;
    });
    this.authService.getUser().pipe(takeUntilDestroyed()).subscribe((user) => {
      this.showMenuItems.set(!!user);
    });
  }

  async itemClickedHandler(item: SidenavItem) {
    if (item.clicked && !!item.subItems) {
      item.clicked = false;
      return;
    }
    this.sidenavItems.update((sidenavItems) => {
      return sidenavItems.map((sidenavItem: SidenavItem) => {
        return {
          ...sidenavItem,
          clicked: sidenavItem === item,
        };
      });
    });
    if (!item.subItems) {
      this.breadcrumbService.resetData();
      this.breadcrumbService.addBreadCrumb({label: item.name, url: item.route});
      await this.router.navigate([item.route]);
    }
  }

  async navToSubRoute(parent: SidenavItem, item: SidenavItem) {
    this.breadcrumbService.resetData();
    this.breadcrumbService.addBreadCrumb({label: parent.name, url: parent.route});
    this.breadcrumbService.addBreadCrumb({label: item.name, url: item.route});
    await this.router.navigate([`${parent.route}${item.route}`]);
  }

  isSubRouteActive(item: SidenavItem): boolean {
    return (
      !!item.subItems?.find((subItem) => {
        return this.currentRoute.includes(subItem.route);
      }) && !item.clicked
    );
  }
}
