<div
  class="h-screen w-screen bg-light dark:bg-dark flex items-center justify-center absolute inset-0 dark:text-white z-50"
>
  <div class="container flex flex-col items-center justify-center px-5">
    <div>
      <div class="max-w-2lg flex items-center justify-center gap-4">
        <div class="text-5xl font-dark font-bold">404</div>
        <div class="h-24 border-r border-black dark:border-white"></div>
        <div>
          <p class="text-2xl md:text-3xl font-light leading-normal">
            {{ "pageNotFound.title" | transloco }}.
          </p>
          <p class="mt-2">{{ "pageNotFound.description" | transloco }}.</p>
        </div>
      </div>
    </div>
    <button
      routerLink="/dashboard"
      class="sc-btn-primary-static mt-8 mx-auto !text-base flex items-center gap-2"
    >
      <i class="bi bi-arrow-left"></i>
      <span> {{ "pageNotFound.back" | transloco }}</span>
    </button>
  </div>
</div>
