import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {concatMap, map, Observable, of} from "rxjs";
import {inject} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../reducers";
import {isLoggedIn} from "../auth.selectors";
import {AuthService} from "../services/auth.service";
import {AuthActions} from "../action-types";
import {catchError} from "rxjs/operators";

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const store = inject(Store<AppState>);
  const authService = inject(AuthService);
  const router = inject(Router);
  // se is logged è true allora non faccio niente, altrimenti refresco il token
  return store.pipe(
    select(isLoggedIn),
    concatMap((loggedIn) => {
      if (loggedIn) {
        return of(loggedIn);
      }
      store.dispatch(AuthActions.refreshToken({isLoading: true}));
      // non dispatcho l'azione di refresh con is loading a false in quanto viene gestito dall'azione di login o logout
      return authService.refreshTokens().pipe(
        map(() => {
          router.navigateByUrl(state.url);
          return true
        }),
        catchError((error) => {
          authService.logout();
          return of(false);
        })
      );
    })
  );
};

