import {ChangeDetectionStrategy, Component, EventEmitter, input, Input, Output, signal} from '@angular/core';
import {AlertSlide} from "../../header.component";
import {alertLevelsColors} from "../../../../../shared/utils/utils";

@Component({
  selector: 'sc-alerts-swiper',
  templateUrl: './alerts-swiper.component.html',
  styleUrls: ['./alerts-swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class AlertsSwiperComponent {
  slides = signal<AlertSlide[]>([]);
  @Output() onAlertClick = new EventEmitter<number>();

  isLoading = input<boolean>(false);

  constructor() {
  }

  @Input() set alerts(slides: AlertSlide[]) {
    this.slides.set(slides);
  };

  getLevelBgColor(id: number): string {
    return alertLevelsColors[id - 1];
  }

  alertClicked(id: number) {
    this.onAlertClick.emit(id);
  }

  htmlToString(description: string): string {
    const withoutLinks = description.replace(/<a\b[^>]*>(.*?)<\/a>/gi, '');
    // Remove HTML tags
    const withoutTags = withoutLinks.replace(/<[^>]*>/g, ' ');
    // Trim whitespace and limit to 300 characters
    const string = withoutTags.trim().substring(0, 200);
    if (string.length === 200) {
      return string + '...';
    }
    return string;
  }
}
