import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpInterceptor} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../reducers";
import {isLoggedIn} from "../auth.selectors";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isUserLogged: boolean = false;

  constructor(
    private readonly authService: AuthService,
    private toastController: ToastrService,
    private store: Store<AppState>
  ) {

    this.store.pipe(select(isLoggedIn)).pipe(takeUntilDestroyed()).subscribe({
      next: (isLogged) => {
        this.isUserLogged = isLogged;
      }
    });
  }

  intercept(req: any, next: any) {
    let authRequest: any;
    let headers: { Language: string, TimeZone: string } = {
      Language: 'IT',
      TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    // if (this.isUserLogged) {
    // headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    // }
    authRequest = req.clone({
      setHeaders: headers,
      withCredentials: true,
    });

    return next.handle(authRequest).pipe(
      catchError((err) => {
        // in case of 401 || 403 http error logout
        if (err.status === 403 || err.status === 401) {
          // this.toastController.error('Unauthorized');
        }
        if (err instanceof HttpErrorResponse && (err.status === 401 || err.status === 403)) {
          // console.log('LOGOUT from interceptor');
          this.authService.logout();
        }

        return throwError(() => err);
      })
    );
  }
}
