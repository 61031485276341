<button
  (click)="toggleTheme()"
  aria-label="toggle theme"
  class="backdrop-blur-sm hover:bg-light-secondary dark:hover:bg-dark-secondary rounded-full w-8 h-8 flex justify-center items-center"
>
  <div class="flex flex-col items-center">
    <label for="toggle" class="text-transparent text-2xs h-1">Toggle theme</label>
    <input
      [checked]="isDarkMode"
      class="toggle"
      id="toggle"
      readonly
      type="checkbox"
    />
  </div>
</button>
