import {ChangeDetectionStrategy, Component, computed, inject, Input, OnDestroy, OnInit, signal, input, output} from '@angular/core';
import {fromEvent, Subject, takeUntil} from "rxjs";
import {MetaData, Notification, NotificationType} from "../../../../../shared/models/utils";
import {Router} from "@angular/router";
import {SocketService} from "../../../../services/socket.service";
import {ModalControllerService} from "../../../../services/modal-controller.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import { SubmenuToggleDirective } from '../../../../../shared/directives/submenu-toggle.directive';
import { SidePanelComponent } from '../../../../../shared/components/side-panel/side-panel.component';
import { NgClass, DatePipe } from '@angular/common';
import { ModalComponent } from '../../../../../shared/components/modal/modal.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'sc-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SubmenuToggleDirective, SidePanelComponent, NgClass, ModalComponent, DatePipe, TranslocoModule]
})
export class NotificationComponent implements OnDestroy, OnInit {
  private readonly router = inject(Router);
  private readonly socketService = inject(SocketService);
  private modalController = inject(ModalControllerService);

  private unsubscribeAll$ = new Subject<void>();
  showSubmenu = signal<boolean>(false);
  selectedNotification: Notification | undefined = undefined;
  readonly loadMoreNotificationChange = output<void>();
  readonly onNotificationMenuClick = output<void>();
  @Input() isLoading: boolean = false;
  readonly notifications = input<Notification[]>([]);
  readonly notificationPage = input<number>(1);

  metaRef: MetaData | undefined = undefined;

  notificationCount = signal<number>(0);

  getNotificationCount = computed(() => this.notificationCount() > 99 ? '99+' : this.notificationCount().toString());
  private sanitizer: DomSanitizer = inject(DomSanitizer);

  constructor() {


    this.socketService.notification$.pipe().subscribe((notification: Notification | undefined) => {
      if (notification) {
        new Audio('assets/audio/notification.mp3').play()
        this.notificationCount.set(this.notificationCount() + 1);
        this.notifications().unshift(notification);
        const bellIcon = document.querySelector('#bell-icon');
        if (bellIcon) {
          bellIcon.classList.add('bell-animation');
        }
      }
    });
  }

  @Input() set meta(meta: MetaData | undefined) {
    this.metaRef = meta;
    this.notificationCount.set(meta?.notificationNotRead ?? 0);
  }

  ngOnInit(): void {
    const bellIcon = document.querySelector('#bell-icon');
    if (bellIcon) {
      fromEvent(bellIcon, 'animationend')
        .pipe(takeUntil(this.unsubscribeAll$))
        .subscribe((event: Event) => {
          bellIcon.classList.remove('bell-animation');
        })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  loadMoreNotification() {
    if (this.metaRef?.last_page !== this.notificationPage()) {
      this.loadMoreNotificationChange.emit();
    }
  }

  async handleNotification(notification: Notification, i: number): Promise<void> {
    if (!notification.payload.notExist) {
      await this.router.navigate([notification.payload.type + 's', ...notification.payload.referenceId.toString().split('/')]);
    }
    else {
      this.selectedNotification = notification;
      this.modalController.open('notification-modal')
    }

    const notifications = this.notifications();
    if (!notifications[i].isRead) {
      notifications[i].isRead = true;
      this.onNotificationMenuClick.emit();
    }
  }

  getIcon(type: NotificationType, subType: string | undefined): string {
    if (type === NotificationType.checkIn) {
      if (subType === 'Check-in') {
        return 'bi-geo-alt'
      }
      if (subType === 'Warning') {
        return 'bi-exclamation-circle'
      }
      if (subType === 'Live-location') {
        return 'bi-cursor'
      }
    }
    if (type === NotificationType.travel) {
      return 'bi-airplane'
    }
    if (type === NotificationType.alert) {
      return 'bi-exclamation-triangle'
    }
    return 'bi-info-circle'
  }

  closeModal() {
    this.modalController.close('notification-modal');
    setTimeout(() => {
      this.selectedNotification = undefined;
    }, 200);
  }

  sanitizeMessage(message: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

  onClickOutside() {
    if (this.notificationCount() > 0) {
      this.notificationCount.set(0);
      this.onNotificationMenuClick.emit();
    }
    this.showSubmenu.set(false);
  }
}
