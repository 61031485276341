import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot,} from '@angular/router';
import {map, Observable} from 'rxjs';
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../reducers";
import {isLoggedIn} from "../auth.selectors";


export const notAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const store = inject(Store<AppState>);
  return store.pipe(
    select(isLoggedIn),
    map((isLogged: boolean) => !isLogged)
  );
};
