import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
  selector: 'st-check-box',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './check-box.component.html',
  styleUrl: './check-box.component.scss'
})
export class CheckBoxComponent {

  isDisabled: boolean = false;
  isChecked: boolean = false;
  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() color: string = 'peer-checked:bg-primary-light peer-checked:dark:bg-primary-dark';
  @Input() reverseBg: boolean = false;

  @Input() set disabled(disabled: boolean) {
    this.isDisabled = disabled;
  }

  @Input() set checked(checked: boolean) {
    this.isChecked = checked;
  }

  change(event: Event) {
    this.onChange.emit((event.target as HTMLInputElement).checked);
  }
}
