import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../../../services/theme.service';
import { take } from 'rxjs';

@Component({
  selector: 'sc-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss'],
})
export class ThemeToggleComponent implements OnInit {
  isDarkMode: boolean = false;

  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    this.themeService.isDarkMode$
      .pipe(take(1))
      .subscribe((isDarkMode: boolean) => {
        this.isDarkMode = isDarkMode;
      });
  }

  toggleTheme() {
    this.isDarkMode = !this.isDarkMode;
    this.themeService.changeActivateTheme();
  }
}
