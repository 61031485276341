{
  "name": "sectra24",
  "version": "2.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --ssl --host=dev-sectra24.ey.com",
    "build": "ng build",
    "build:production": "ng build --configuration=production",
    "build:development": "ng build --configuration=development",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.9",
    "@angular/common": "^18.2.9",
    "@angular/compiler": "^18.2.9",
    "@angular/core": "^18.2.9",
    "@angular/forms": "^18.2.9",
    "@angular/platform-browser": "^18.2.9",
    "@angular/platform-browser-dynamic": "^18.2.9",
    "@angular/router": "^18.2.9",
    "@angular/service-worker": "^18.2.9",
    "@mapbox/mapbox-gl-draw": "^1.4.1",
    "@mapbox/mapbox-gl-geocoder": "^5.0.1",
    "@ngneat/transloco": "^4.2.2",
    "@ngrx/effects": "^18.1.0",
    "@ngrx/operators": "^18.1.0",
    "@ngrx/router-store": "^18.1.0",
    "@ngrx/signals": "^18.1.0",
    "@ngrx/store": "^18.1.0",
    "@ngrx/store-devtools": "^18.1.0",
    "@turf/turf": "^6.5.0",
    "bootstrap-icons": "^1.11.2",
    "chart.js": "^4.2.1",
    "chartjs-adapter-dayjs-3": "^1.2.3",
    "chartjs-plugin-datalabels": "^2.2.0",
    "chartjs-plugin-zoom": "^2.0.0",
    "dayjs": "^1.11.7",
    "dotenv": "^16.0.3",
    "flatpickr": "^4.6.13",
    "mapbox-gl": "^2.13.0",
    "mapbox-gl-controls": "^2.3.5",
    "ng2-pdf-viewer": "^10.2.2",
    "ngx-toastr": "^19.0.0",
    "quill": "^2.0.2",
    "rxjs": "~7.8.0",
    "swiper": "^9.0.5",
    "tailwindcss-motion": "^0.3.0-beta",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.10",
    "@angular/cli": "~18.2.10",
    "@angular/compiler-cli": "^18.2.9",
    "@types/jasmine": "~4.3.0",
    "@types/mapbox__mapbox-gl-draw": "^1.3.3",
    "@types/mapbox__mapbox-gl-geocoder": "^4.7.3",
    "@types/mapbox-gl": "^2.7.10",
    "@types/node": "^18.13.0",
    "@types/quill": "^2.0.10",
    "autoprefixer": "^10.4.14",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "postcss": "^8.4.23",
    "tailwindcss": "^3.3.2",
    "typescript": "~5.5.4"
  }
}
