@if (isLoading()) {
  <div class="flex items-center justify-center h-screen w-screen">
    <div class="flex flex-col gap-4 justify-center items-center w-full">
      <div class="loader flex justify-center">
        <!--      <div class="iata_code departure_city">CDG</div>-->
        <!--      <div class="plane">-->
        <img [src]="'assets/images/loader/traveller.gif'">
      <!--      </div>-->
      <!--      <div class="earth-wrapper">-->
      <!--        <div class="earth"></div>-->
    <!--      </div>-->
  </div>
  <span class="text-xl sc-text-default">{{ message() }}</span>
</div>
</div>
}
