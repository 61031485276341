import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppRoutingModule } from './app/app-routing.module';
import { TranslocoRootModule } from './app/transloco-root.module';
import { CoreModule } from './app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AuthModule } from './app/core/auth/auth.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { isDevMode, importProvidersFrom } from '@angular/core';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app/app.component';


bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AppRoutingModule, TranslocoRootModule, CoreModule, FormsModule, ReactiveFormsModule, PdfViewerModule, AuthModule.forRoot(), StoreModule.forRoot({}, {}), StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }), 
        // EntityDataModule.forRoot({}),
        StoreRouterConnectingModule.forRoot(), EffectsModule.forRoot([]), ServiceWorkerModule.register('ngsw-worker.js', {
            // enabled: !isDevMode(),
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:5000'
        })),
        // {provide: FIREBASE_OPTIONS, useValue: environment.FIREBASE_CONF}
        provideHttpClient(withInterceptorsFromDi())
    ]
})
  .catch(err => console.error(err));
