<div class="relative">
  <div class="sc-card !p-0 w-10 h-10 flex items-center justify-center">
    <button class="z-10" id="language-submenu-button" aria-label="set language">
      <img
        [src]="'assets/images/icons/flags/' + languageServices.userLanguage() + '.svg'"
        alt="flag"
        class="border h-8 w-8 rounded-full border-light dark:border-dark-secondary"
      />
    </button>
  </div>

  @if (showSubmenu()) {
    <div class="sc-card sc-text-default absolute right-0 mt-1 w-56 !p-0 z-[99]" id="language-submenu">
      @for (language of availableLanguages; track $index) {
        <div
          class="p-4 border-b-[0.5px] border-white dark:border-dark-secondary sc-hover rounded-3xl"
        >
          <button
            (click)="setUserLanguage(language.value.toString())"
            [disabled]="language.value === languageServices.userLanguage()"
            class="flex items-center gap-4 disabled:opacity-50 w-full"
          >
            <img
              [src]="'assets/images/icons/flags/' + language.value + '.svg'"
              alt="flag"
              class="border h-8 w-8 rounded-full border-light dark:border-dark-secondary"
            />
            <p class="capitalize text-sm">{{ language.label }}</p>
          </button>
        </div>
      }
    </div>
  }
</div>

