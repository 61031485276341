import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private message$: BehaviorSubject<string> = new BehaviorSubject<string>('Loading...');

  constructor() {
  }

  showLoader(message: string = 'Loading...') {
    this.isLoading$.next(true);
    this.message$.next(message);
  }

  hideLoader(delay: number = 0) {
    setTimeout(() => {
      this.isLoading$.next(false);
    }, delay);
  }

  isLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  getMessage(): Observable<string> {
    return this.message$.asObservable();
  }


}
