import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {authGuard} from "./core/auth/guards/auth.guard";
import {EnumRoles} from "./core/auth/models/roles.model";
import {PageNotFoundComponent} from "./pages/page-not-found/page-not-found.component";
import {notAuthGuard} from "./core/auth/guards/not-auth.guard";
import {
  ResetPasswordActivationAccountComponent
} from "./pages/reset-password-activation-account/reset-password-activation-account.component";
import {ResetPasswordEmailComponent} from "./pages/reset-password-email/reset-password-email.component";
import {PrivacyPolicyComponent} from "./pages/privacy-policy/privacy-policy.component";
import {rolesGuard} from "./core/auth/guards/roles.guard";


const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {
    path: 'auth',
    canActivate: [notAuthGuard],
    loadChildren: () =>
      import('./core/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'companies',
    canActivate: [authGuard, rolesGuard],
    data: {roles: [EnumRoles.AdminEY, EnumRoles.Analyst, EnumRoles.CompanyAdmin, EnumRoles.Traveller]},
    loadChildren: () =>
      import('./modules/companies/companies.module').then(
        (m) => m.CompaniesModule
      ),
  },
  {
    path: 'users',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/users/users.module').then((m) => m.UsersModule),
  },
  // {
  //   path: 'test',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./modules/test/test.module').then((m) => m.TestModule),
  // },
  {
    path: 'travels',
    canActivate: [authGuard, rolesGuard],
    data: {
      roles: [
        EnumRoles.AdminEY,
        EnumRoles.CompanyAdmin,
        EnumRoles.Analyst,
        EnumRoles.Traveller,
      ],
    },
    loadChildren: () =>
      import('./modules/travels/travels.module').then((m) => m.TravelsModule),
  },
  {
    path: 'alerts',
    canActivate: [authGuard, rolesGuard],
    data: {
      roles: [
        EnumRoles.AdminEY,
        EnumRoles.CompanyAdmin,
        EnumRoles.Analyst,
        EnumRoles.Traveller,
      ],
    },
    loadChildren: () =>
      import('./modules/alerts/alerts.module').then((m) => m.AlertsModule),
  },
  {
    path: 'poi',
    canActivate: [authGuard, rolesGuard],
    data: {
      roles: [
        EnumRoles.AdminEY,
        EnumRoles.CompanyAdmin,
        EnumRoles.Analyst,
        EnumRoles.Traveller,
      ],
    },
    loadChildren: () =>
      import('./modules/poi/poi.module').then((m) => m.PoiModule),
  },
  {
    path: 'checkins',
    canActivate: [authGuard, rolesGuard],
    data: {
      roles: [
        EnumRoles.AdminEY,
        EnumRoles.CompanyAdmin,
        EnumRoles.Analyst,
        EnumRoles.Traveller,
      ],
    },
    loadChildren: () =>
      import('./modules/check-in/check-in.module').then((m) => m.CheckInModule),
  },
  // WORLD SECTION
  {
    path: 'iata',
    canActivate: [authGuard, rolesGuard],
    data: {roles: [EnumRoles.AdminEY, EnumRoles.Analyst, EnumRoles.CompanyAdmin, EnumRoles.Traveller]},
    loadChildren: () =>
      import('./modules/world/modules/iata/iata.module').then((m) => m.IataModule),
  },
  {
    path: 'hotels',
    canActivate: [authGuard, rolesGuard],
    data: {roles: [EnumRoles.AdminEY, EnumRoles.Analyst, EnumRoles.CompanyAdmin, EnumRoles.Traveller]},
    loadChildren: () =>
      import('./modules/world/modules/hotels/hotels.module').then((m) => m.HotelsModule),
  },
  {
    path: 'cities',
    canActivate: [authGuard, rolesGuard],
    data: {roles: [EnumRoles.AdminEY, EnumRoles.Analyst, EnumRoles.CompanyAdmin, EnumRoles.Traveller]},
    loadChildren: () =>
      import('./modules/world/modules/cities/cities.module').then((m) => m.CitiesModule),
  },
  {
    path: 'countries',
    canActivate: [authGuard, rolesGuard],
    data: {roles: [EnumRoles.AdminEY, EnumRoles.Analyst, EnumRoles.CompanyAdmin, EnumRoles.Traveller]},
    loadChildren: () =>
      import('./modules/world/modules/countries/countries.module').then((m) => m.CountriesModule),
  },
  {
    path: 'imports',
    canActivate: [authGuard, rolesGuard],
    data: {roles: [EnumRoles.AdminEY]},
    loadChildren: () =>
      import('./modules/imports/imports.module').then((m) => m.ImportsModule),
  },
  {
    path: 'imported-data',
    canActivate: [authGuard, rolesGuard],
    data: {roles: [EnumRoles.AdminEY, EnumRoles.Analyst]},
    loadChildren: () =>
      import('./modules/imported-data/imported-data.module').then((m) => m.ImportedDataModule),
  },
  {
    path: 'activation/:token',
    canActivate: [notAuthGuard],
    component: ResetPasswordActivationAccountComponent

  },
  {
    path: 'reset-password/:token',
    // canActivate: [NotAuthGuard],
    component: ResetPasswordActivationAccountComponent

  },
  {
    path: 'reset-password',
    canActivate: [notAuthGuard],
    component: ResetPasswordEmailComponent

  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
